import { TypeTag, TypeTask, TypeTaskLink } from '../../../types';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { ClearIcon, DateTimePicker } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import {
  AddLink,
  Clear,
  Event,
  NotificationsPaused,
} from '@mui/icons-material';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AuthContext,
  PlannerContext,
  ProjectContext,
} from '../../../providers';
import { useGetTagsByType } from '../../../api';
import { format, parseISO } from 'date-fns';
import { DurationInput, TaskDialog } from '../../_common';

export const EditTaskCard = () => {
  const { performingAction } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { activeTask, handleSaveTask } = useContext(PlannerContext);

  const [editTaskName, setEditTaskName] = useState<string>('');
  const [editTaskStartAt, setEditTaskStartAt] = useState<Date | null>(null);
  const [editTaskEndAt, setEditTaskEndAt] = useState<Date | null>(null);
  const [editTaskDurationEstimate, setEditTaskDurationEstimate] = useState<
    number | null
  >(null);
  const [editTaskSnoozeUntil, setEditTaskSnoozeUntil] = useState<Date | null>(
    null,
  );
  const [editTaskDueAt, setEditTaskDueAt] = useState<Date | null>(null);
  const [editTaskLinks, setEditTaskLinks] = useState<TypeTaskLink[]>([]);
  const [editTaskTags, setEditTaskTags] = useState<TypeTag | null>(null);
  const [showAddEvent, setShowAddEvent] = useState<boolean>(false);
  const [showAddSnooze, setShowAddSnooze] = useState<boolean>(false);

  const { data: taskTags } = useGetTagsByType('task');
  const editTaskNameRef = useRef<HTMLInputElement | null>(null);

  const handleEdit = (task: TypeTask) => {
    setEditTaskName(task?.name || '');

    setShowAddEvent(!!task.startAt || !!task.endAt || !!task.durationEstimate);
    setEditTaskStartAt(task.startAt ? parseISO(task.startAt) : null);
    setEditTaskEndAt(task.endAt ? parseISO(task.endAt) : null);
    setEditTaskDurationEstimate(task?.durationEstimate || 0);

    setShowAddSnooze(!!task.snoozeUntil);
    setEditTaskSnoozeUntil(
      task.snoozeUntil ? parseISO(task.snoozeUntil) : null,
    );

    setEditTaskDueAt(task.dueAt ? parseISO(task.dueAt) : null);
    setEditTaskTags(task.tags?.length ? task.tags[0] : null);

    setEditTaskLinks(task.taskLinks || []);
  };

  const ensureTags = (tag: TypeTag | null) => {
    if (!tag) {
      return [];
    }
    return [
      {
        ...tag,
        type: 'task',
        position: 1,
        organization: profile?.organizationContext,
      },
    ];
  };

  const onHandleReset = () => {
    if (!activeTask) {
      return;
    }
    handleEdit(activeTask);
    editTaskNameRef.current?.focus();
  };

  const onHandleSave = () => {
    handleSaveTask({
      ...activeTask,
      name: editTaskName,
      startAt: editTaskStartAt
        ? format(editTaskStartAt, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      endAt: editTaskEndAt
        ? format(editTaskEndAt, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      dueAt: editTaskDueAt
        ? format(editTaskDueAt, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      snoozeUntil: editTaskSnoozeUntil
        ? format(editTaskSnoozeUntil, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      durationEstimate: editTaskDurationEstimate ?? undefined,
      tags: ensureTags(editTaskTags),
      taskLinks: editTaskLinks,
      profile: profile ?? undefined,
      organization: profile?.organizationContext,
    });
  };

  useEffect(() => {
    onHandleReset();
  }, [activeTask]);

  const handleGetOptionLabel = (option: string | TypeTag) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name || '';
  };

  const handleTagOptionEqualToValue = (
    option: TypeTag,
    value: TypeTag | string,
  ) => (typeof value === 'string' ? option.name === value : option === value);

  const handleTagsChange = (
    _event: React.SyntheticEvent,
    newValue: TypeTag | string | null,
  ) => {
    if (!newValue) {
      return;
    }
    setEditTaskTags(
      typeof newValue === 'string'
        ? { name: newValue, type: 'task' }
        : newValue,
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onHandleSave();
    }
  };

  const handleAddLink = () => {
    setEditTaskLinks([
      ...editTaskLinks,
      { url: '', organization: profile?.organizationContext },
    ]);
  };

  const handleEditTaskLink = (index: number, url: string) => {
    setEditTaskLinks(
      editTaskLinks.map((link, i) => (i === index ? { ...link, url } : link)),
    );
  };

  const handleRemoveTaskLink = (index: number) => {
    setEditTaskLinks(editTaskLinks.filter((_link, i) => i !== index));
  };

  const startAtEndAt = () => (
    <>
      {showAddEvent ? (
        <>
          <Grid item xs={6} md={3}>
            <DateTimePicker
              label={'Start At'}
              value={editTaskStartAt}
              onChange={(newValue) => setEditTaskStartAt(newValue)}
              disabled={performingAction}
              componentsProps={{
                textField: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setEditTaskStartAt(null)}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DateTimePicker
              label={'End At'}
              value={editTaskEndAt}
              onChange={(newValue) => setEditTaskEndAt(newValue)}
              disabled={performingAction}
              componentsProps={{
                textField: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setEditTaskEndAt(null)}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DurationInput
              label={'Duration Estimate'}
              duration={editTaskDurationEstimate}
              onDurationChange={setEditTaskDurationEstimate}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <IconButton
              onClick={() => {
                setEditTaskStartAt(null);
                setEditTaskEndAt(null);
                setEditTaskDurationEstimate(null);
                setShowAddEvent(false);
              }}
            >
              <Clear />
            </IconButton>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowAddEvent(true)}
            startIcon={<Event />}
          >
            Add Event
          </Button>
        </Grid>
      )}
    </>
  );

  const snoozeFields = () => (
    <>
      {showAddSnooze ? (
        <>
          <Grid item xs={9}>
            <DateTimePicker
              label={'Snooze Until'}
              value={editTaskSnoozeUntil}
              onChange={(newValue) => setEditTaskSnoozeUntil(newValue)}
              disabled={performingAction}
              componentsProps={{
                textField: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setEditTaskSnoozeUntil(null)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={3} justifyContent={'flex-end'} display={'flex'}>
            <IconButton
              onClick={() => {
                setEditTaskSnoozeUntil(null);
                setShowAddSnooze(false);
              }}
            >
              <Clear />
            </IconButton>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowAddSnooze(true)}
            startIcon={<NotificationsPaused />}
          >
            Add Snooze
          </Button>
        </Grid>
      )}
    </>
  );

  const linkFields = () => (
    <>
      {editTaskLinks.map((taskLink, index) => (
        <Fragment key={`edit-task-links-${index}`}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              label={`Link ${index + 1}`}
              placeholder={`Link ${index + 1}`}
              value={taskLink.url}
              onChange={(event) =>
                handleEditTaskLink(index, event.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <IconButton onClick={() => handleRemoveTaskLink(index)}>
              <Clear />
            </IconButton>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleAddLink}
          startIcon={<AddLink />}
        >
          Add Link
        </Button>
      </Grid>
    </>
  );

  const mainFields = () => (
    <>
      <Grid item xs={6}>
        <DateTimePicker
          label={'Due Date'}
          value={editTaskDueAt}
          onChange={(newValue) => setEditTaskDueAt(newValue)}
          disabled={performingAction}
          componentsProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setEditTaskDueAt(null)}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          freeSolo
          clearOnBlur
          value={editTaskTags}
          options={taskTags || []}
          getOptionLabel={handleGetOptionLabel}
          filterSelectedOptions
          isOptionEqualToValue={handleTagOptionEqualToValue}
          onChange={handleTagsChange}
          renderInput={(params) => (
            <TextField {...params} label="Project" placeholder="Project" />
          )}
          disabled={performingAction}
        />
      </Grid>
    </>
  );

  return (
    <TaskDialog
      title={'Edit Task'}
      saveFn={onHandleSave}
      resetFn={onHandleReset}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder={'Task Name'}
            value={editTaskName}
            onChange={(event) => setEditTaskName(event.target.value)}
            onKeyDown={handleKeyPress}
            disabled={performingAction}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setEditTaskName('');
                    editTaskNameRef.current?.focus();
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        {startAtEndAt()}
        {snoozeFields()}
        {linkFields()}
        {mainFields()}
      </Grid>
    </TaskDialog>
  );
};
