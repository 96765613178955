import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  linkWithPopup,
  OAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  unlink,
  updateEmail,
  updatePassword,
  User,
} from 'firebase/auth';
import { auth } from '../firebaseapp';
import {
  useGetProfile,
  usePostGoogleCalendarCode,
  usePostProfileAppearance,
  usePostProfileHelpdesk,
} from '../api';
import {
  TypeAppearance,
  TypeAppRoute,
  TypeAuthProvider,
  TypeLinkDTO,
  TypeProfile,
  TypeRole,
  TypeRoleOptions,
} from '../types';
import { ProjectContext } from './ProjectProvider';
import { DialogContext } from './DialogProvider';

type AuthContextType = {
  user: User | null;
  profile: TypeProfile | null;
  roles: TypeRole[];
  matchingRoute: TypeAppRoute | null;
  userRoutes: TypeAppRoute[];
  hasRole: (_checkRoles?: TypeRoleOptions[]) => boolean;
  signInWithAuthProvider: (_provider: TypeAuthProvider) => void;
  signInEmailAndPassword: (_email: string, _password: string) => void;
  signInEmailLink: (_email: string, _emailLink: string) => void;
  signUpEmailAddressAndPassword: (_email: string, _password: string) => void;
  signOut: () => void;
  resetPassword: (_email: string) => void;
  sendSignInLink: (_email: string) => void;
  changeEmail: (_email: string) => void;
  changePassword: (_password: string) => void;
  setAppearance: (_appearance: TypeAppearance) => void;
  setHelpdesk: (_helpdesk: boolean) => void;
  deleteAccount: () => void;
  verifyEmailAddress: () => void;
  linkAuthProvider: (_authProvider: TypeAuthProvider) => void;
  unlinkAuthProvider: (_authProvider: TypeAuthProvider) => void;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  profile: null,
  roles: [],
  matchingRoute: null,
  userRoutes: [],
  hasRole: (_checkRoles) => false,
  signInWithAuthProvider: (_provider) => {},
  signInEmailAndPassword: (_email, _password) => {},
  signInEmailLink: (_email, _emailLink) => {},
  signUpEmailAddressAndPassword: (_email, _password) => {},
  signOut: () => {},
  resetPassword: (_email) => {},
  sendSignInLink: (_email) => {},
  changeEmail: (_email) => {},
  changePassword: (_password) => {},
  setAppearance: (_appearance) => {},
  setHelpdesk: (_helpdesk) => {},
  deleteAccount: () => {},
  verifyEmailAddress: () => {},
  linkAuthProvider: (_authProvider) => {},
  unlinkAuthProvider: (_authProvider) => {},
});

type AuthProviderProps = {
  children: React.ReactNode;
};
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const { appRoutes, homeRoutes, setSnackbar, setPerformingAction } =
    useContext(ProjectContext);
  const { closeDialog } = useContext(DialogContext);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<TypeProfile | null>(null);
  const [roles, setRoles] = useState<TypeRole[]>([]);
  const [previousPage, setPreviousPage] = useState<string | null>(null);

  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
  } = useGetProfile({ enabled: !!user });

  // const {
  //   data: profileProductsData,
  //   isLoading: profileProductsLoading,
  //   error: profileProductsError,
  // } = useGetProfileProducts({ enabled: !!user });

  const { mutate: postProfileAppearance } = usePostProfileAppearance();
  const { mutate: postProfileHelpdesk } = usePostProfileHelpdesk();
  const { mutate: postGCalCode } = usePostGoogleCalendarCode();

  useEffect(() => {
    if (!user || profileLoading || profileError || !profileData) {
      setProfile(null);
      setRoles([]);
    } else {
      setProfile(profileData ?? null);
      setRoles(profileData?.authorities ?? []);
      const params = sessionStorage.getItem('params');
      sessionStorage.removeItem('params');
      if (params) {
        const searchParams = new URLSearchParams(params);
        const code = searchParams.get('code');
        if (code) {
          console.log('sending code', code);
          const input: TypeLinkDTO = { url: code };
          postGCalCode(input, {
            onSuccess: () => setSnackbar('Google Calendar linked'),
            onSettled: () => {
              console.log('something');
              setPerformingAction(false);
            },
          });
        }
      }
    }
  }, [user, profileData, profileLoading, profileError]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (value) => {
      setUser(value ?? null);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setPreviousPage(sessionStorage.getItem('previousPage'));
    sessionStorage.setItem('previousPage', pathname);
    sessionStorage.setItem('systemNavigated', 'false');
  }, [pathname]);

  const userRoutes = useMemo(
    () =>
      appRoutes.filter((route) =>
        route.roles && route.roles.length > 0
          ? roles.some((role) =>
              route.roles!!.some(
                (r) => r && role.authority && role.authority === r,
              ),
            )
          : true,
      ),
    [appRoutes, roles],
  );

  const matchingRoute = useMemo(
    () =>
      userRoutes.find((route) => {
        if (route.path) {
          const pattern = new RegExp(
            `^${route.path.replace(/:[^\s/]+/g, '([\\w-]+)')}$`,
          );
          return pattern.test(pathname);
        }
        return false;
      }) || null,
    [userRoutes, pathname],
  );

  useEffect(() => {
    if (matchingRoute?.public) return;
    if (!user || !profile) {
      navigateUsingSystem('/');
      return;
    }
    if (!matchingRoute) {
      navigateUsingSystem('/');
      return;
    }
    if (matchingRoute.requireProductAccess && !hasProductAccess()) {
      navigateUsingSystem('/');
      return;
    }
    if (profile && !profile?.organizationContext) {
      navigateUsingSystem('/account');
      return;
    }
    const systemNavigated = sessionStorage.getItem('systemNavigated');
    if (
      systemNavigated === 'true' &&
      previousPage &&
      previousPage !== pathname
    ) {
      navigate(previousPage);
      return;
    }
    const loginHome = homeRoutes[roles[0].authority as TypeRoleOptions] || '/';
    if (
      pathname === '/' &&
      pathname !== loginHome &&
      systemNavigated === 'true'
    ) {
      navigateUsingSystem(loginHome);
      return;
    }
  }, [matchingRoute, user, profile, roles]);

  const navigateUsingSystem = (path: string) => {
    sessionStorage.setItem('systemNavigated', 'true');
    if (!sessionStorage.getItem('params')) {
      sessionStorage.setItem('params', searchParams.toString());
    }
    navigate(path);
  };

  const hasRole = (checkRoles?: string[]) => {
    if (!checkRoles || checkRoles.length === 0) return true;
    return roles.some((role) =>
      checkRoles.some((r) => r && role.authority && role.authority === r),
    );
  };

  // const hasPBSProduct = () =>
  //   projectName === 'pbs' && !!profileProductsData?.memberships.length;

  const hasProductAccess = () => hasRole(['SYSADMIN']);

  const handleAuthCallback = (promise: Promise<void>) => {
    promise
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;
        console.log(code);
        console.log(message);
        setSnackbar(message);
      })
      .finally(() => {
        setPerformingAction(false);
      });
  };

  const signInWithAuthProvider = (provider: TypeAuthProvider) => {
    if (!provider) {
      return;
    }
    const authProvider = new OAuthProvider(provider.id);
    if (auth.currentUser) {
      return;
    }

    setPerformingAction(true);
    handleAuthCallback(
      signInWithPopup(auth, authProvider).then((value) => {
        const user = value.user;
        if (!user || !user.uid) {
          return;
        }
        closeDialog('signInDialog');
        closeDialog('signUpDialog');
        setSnackbar(`Signed in as ${user.displayName || user.email}`);
      }),
    );
  };

  const resetPassword = (email: string) => {
    setPerformingAction(true);
    handleAuthCallback(
      sendPasswordResetEmail(auth, email).then(() => {
        setSnackbar(`Sent password reset e-mail`);
      }),
    );
  };

  const signInEmailAndPassword = (email: string, password: string) => {
    if (auth.currentUser) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      signInWithEmailAndPassword(auth, email, password).then((value) => {
        const user = value.user;
        if (!user || !user.uid) {
          return;
        }
        closeDialog('signInDialog');
        setSnackbar(`Signed in as ${user.displayName || user.email}`);
      }),
    );
  };

  const signInEmailLink = (email: string, emailLink: string) => {
    if (auth.currentUser) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      signInWithEmailLink(auth, email, emailLink).then((value) => {
        const user = value.user;
        if (!user || !user.uid) {
          return;
        }
        closeDialog('signInDialog');
        setSnackbar(`Signed in as ${user.displayName || user.email}`);
      }),
    );
  };

  const sendSignInLink = (email: string) => {
    if (auth.currentUser) {
      return;
    }
    const actionCodeSettings = {
      url: '',
      handleCodeInApp: true,
    };
    setPerformingAction(true);
    handleAuthCallback(
      sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
        closeDialog('signInDialog');
        setSnackbar(`Sent sign-in e-mail to ${email}`);
      }),
    );
  };

  const signUpEmailAddressAndPassword = (email: string, password: string) => {
    if (auth.currentUser) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      createUserWithEmailAndPassword(auth, email, password).then((value) => {
        const user = value.user;
        if (!user || !user.uid) {
          return;
        }
        closeDialog('signUpDialog');
        setSnackbar(`Signed in as ${user.displayName || user.email}`);
      }),
    );
  };

  const signOut = () => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      auth.signOut().then(() => {
        closeDialog('signOutDialog');
      }),
    );
  };

  const changeEmail = (email: string) => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      updateEmail(user, email).then(() => {
        setSnackbar('Changed e-mail address');
      }),
    );
  };

  const changePassword = (password: string) => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      updatePassword(user, password).then(() => {
        setSnackbar('Changed password');
      }),
    );
  };

  const setAppearance = (appearance: TypeAppearance) => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    postProfileAppearance(appearance, {
      onSuccess: () => setSnackbar('Changed appearance'),
      onSettled: () => setPerformingAction(false),
    });
  };

  const setHelpdesk = (helpdesk: boolean) => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    postProfileHelpdesk(helpdesk, {
      onSuccess: () => setSnackbar('Changed helpdesk'),
      onSettled: () => setPerformingAction(false),
    });
  };

  const deleteAccount = () => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      user.delete().then(() => {
        closeDialog('deleteAccountDialog');
        setSnackbar('Deleted account');
      }),
    );
  };

  const verifyEmailAddress = () => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      sendEmailVerification(user).then(() => {
        setSnackbar('Sent e-mail verification');
      }),
    );
  };

  const linkAuthProvider = (authProvider: TypeAuthProvider) => {
    if (!user) {
      return;
    }
    const provider = new OAuthProvider(authProvider.id);
    setPerformingAction(true);
    handleAuthCallback(
      linkWithPopup(user, provider).then(() => {
        setSnackbar(`${authProvider.name} linked`);
      }),
    );
  };

  const unlinkAuthProvider = (authProvider: TypeAuthProvider) => {
    if (!user) {
      return;
    }
    setPerformingAction(true);
    handleAuthCallback(
      unlink(user, authProvider.id).then(() => {
        setSnackbar(`${authProvider.name} linked`);
      }),
    );
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        roles,
        matchingRoute,
        userRoutes,
        hasRole,
        signInWithAuthProvider,
        signInEmailAndPassword,
        signInEmailLink,
        signUpEmailAddressAndPassword,
        signOut,
        resetPassword,
        sendSignInLink,
        changeEmail,
        changePassword,
        setAppearance,
        setHelpdesk,
        deleteAccount,
        verifyEmailAddress,
        linkAuthProvider,
        unlinkAuthProvider,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
