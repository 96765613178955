import React, { useContext } from 'react';
import { Box, List } from '@mui/material';
import { PlannerContext } from '../../../providers';
import { ViewWorkflowCard } from './ViewWorkflowCard';

export const WorkflowList = () => {
  const {
    workflows,
    showChips,
    activeWorkflow,
    handleSetEditWorkflow,
    handleSaveTask,
  } = useContext(PlannerContext);
  return (
    <Box>
      <List>
        {workflows.map((item) => (
          <ViewWorkflowCard
            key={`workflow-${item.id}`}
            showChips={showChips}
            workflow={item}
            activeWorkflow={activeWorkflow}
            onSetEditWorkflow={handleSetEditWorkflow}
            onSave={handleSaveTask}
          />
        ))}
      </List>
    </Box>
  );
};
