import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  TypeCalendarEvent,
  TypeDocument,
  TypeEditTaskField,
  TypeTag,
  TypeTask,
  TypeWorkflow,
} from '../../types';
import {
  useGetTasks,
  useGetWorkflows,
  usePostTask,
  usePostWorkflow,
} from '../../api';
import { ProjectContext } from '../ProjectProvider';
import { DialogContext } from '../DialogProvider';
import { parseISO } from 'date-fns';
import { stableSort } from '../../components';
import { TimeContext } from '../TimeProvider';
import { uniq, without } from 'lodash';

type PlannerContextType = {
  // global view
  selectedTab: number;
  setSelectedTab: (_tab: number) => void;
  showFilters: boolean;
  setShowFilters: (_showFilters: boolean) => void;
  showChips: boolean;
  setShowChips: (_checked: boolean) => void;
  selectedTags: TypeTag[];
  handleSelectTag: (_tag: TypeTag) => void;
  searchValue: string;
  setSearchValue: (_value: string) => void;
  searchValueRef: React.RefObject<HTMLInputElement | null> | null;
  // task view
  activeTask: TypeTask | null;
  tasks: TypeTask[];
  setTasks: (_tasks: TypeTask[]) => void;
  handleSetEditTask: (_task: TypeTask) => void;
  handleSaveTask: (_updatedTask: TypeTask) => void;
  orderBy: TypeEditTaskField;
  setOrderBy: (_property: TypeEditTaskField) => void;
  isDescending: boolean;
  setIsDescending: (_isDescending: boolean) => void;
  showCompleted: boolean;
  setShowCompleted: (_checked: boolean) => void;
  showDeleted: boolean;
  setShowDeleted: (_checked: boolean) => void;
  showSnoozed: boolean;
  setShowSnoozed: (_checked: boolean) => void;
  // workflow view
  activeWorkflow: TypeWorkflow | null;
  workflows: TypeWorkflow[];
  setWorkflows: (_workflows: TypeWorkflow[]) => void;
  handleSetEditWorkflow: (_workflow: TypeWorkflow) => void;
  handleSaveWorkflow: (_updatedWorkflow: TypeWorkflow) => void;
  // documents
  documents: TypeDocument[];
  // events
  events: TypeCalendarEvent[];

  isPlannerReady: boolean;
};

export const PlannerContext = createContext<PlannerContextType>({
  // global view
  selectedTab: 0,
  setSelectedTab: () => {},
  showFilters: true,
  setShowFilters: () => {},
  showChips: true,
  setShowChips: () => {},
  selectedTags: [],
  handleSelectTag: () => {},
  searchValue: '',
  setSearchValue: () => {},
  searchValueRef: null,
  activeTask: null,
  // task view
  tasks: [],
  setTasks: () => {},
  handleSetEditTask: () => {},
  handleSaveTask: () => {},
  orderBy: 'priorityValue',
  setOrderBy: () => {},
  isDescending: true,
  setIsDescending: () => {},
  showCompleted: false,
  setShowCompleted: () => {},
  showDeleted: false,
  setShowDeleted: () => {},
  showSnoozed: false,
  setShowSnoozed: () => {},
  // workflow view
  activeWorkflow: null,
  workflows: [],
  setWorkflows: () => {},
  handleSetEditWorkflow: () => {},
  handleSaveWorkflow: () => {},
  // documents
  documents: [],
  // events
  events: [],
  isPlannerReady: false,
});

export type PlannerProviderProps = {
  children: React.ReactNode;
};

export const PlannerProvider = ({ children }: PlannerProviderProps) => {
  const { setPerformingAction, setSnackbar } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { currentMinute } = useContext(TimeContext);

  const [activeTask, setActiveTask] = useState<TypeTask | null>(null);
  const [activeWorkflow, setActiveWorkflow] = useState<TypeWorkflow | null>(
    null,
  );
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [showSnoozed, setShowSnoozed] = useState<boolean>(false);
  const [showChips, setShowChips] = useState<boolean>(true);
  const [selectedTags, setSelectedTags] = useState<TypeTag[]>([]);
  const [isDescending, setIsDescending] = useState<boolean>(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<keyof TypeTask>('priorityValue');
  const [searchValue, setSearchValue] = useState<string>('');
  const [tasks, setTasks] = useState<TypeTask[]>([]);
  const [workflows, setWorkflows] = useState<TypeWorkflow[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { data: tasksData, isLoading: tasksLoading } = useGetTasks();
  const { data: workflowsData, isLoading: workflowsLoading } =
    useGetWorkflows();
  const { mutate: updateTask } = usePostTask();
  const { mutate: updateWorkflow } = usePostWorkflow();

  const searchValueRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    console.log('sorting', tasksData, orderBy, isDescending, selectedTags);
    if (!tasksData) {
      return;
    }
    if (searchValue) {
      const filteredTasks = tasksData
        .filter((task) => task.name?.includes(searchValue))
        .filter((task) => (showDeleted ? true : !task.deletedAt));
      setTasks(filteredTasks);
      return;
    }
    const filteredTasks = tasksData
      .filter(
        (task) =>
          selectedTags.length === 0 ||
          selectedTags.some((t) => task.tags?.some((tt) => tt.id === t.id)),
      )
      .filter((task) => (showCompleted ? true : !task.completedAt))
      .filter((task) => (showDeleted ? true : !task.deletedAt))
      .filter((task) =>
        showSnoozed
          ? true
          : !task.snoozeUntil || parseISO(task.snoozeUntil) <= currentMinute,
      );
    const sortedTasks = stableSort<TypeTask>(filteredTasks, {
      property: orderBy,
      isDescending,
    });
    setTasks(sortedTasks);
  }, [
    tasksData,
    searchValue,
    orderBy,
    isDescending,
    selectedTags,
    showCompleted,
    showDeleted,
    currentMinute,
  ]);

  useEffect(() => {
    if (!workflowsData) {
      return;
    }
    setWorkflows(workflowsData);
  }, [workflowsData]);

  const handleSetEditTask = (task: TypeTask) => {
    setActiveTask(task);
    openDialog('taskDialog');
  };

  const handleSetEditWorkflow = (workflow: TypeWorkflow) => {
    setActiveWorkflow(workflow);
    openDialog('workflowDialog');
  };

  const handleSaveTask = (updatedTask: TypeTask) => {
    setPerformingAction(true);
    updateTask(updatedTask, {
      onSuccess: (data) => {
        setSnackbar(`Saved: ${data.name}`);
        setActiveTask(data);
        closeDialog('taskDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleSaveWorkflow = (updatedWorkflow: TypeWorkflow) => {
    setPerformingAction(true);
    updateWorkflow(updatedWorkflow, {
      onSuccess: (data) => {
        setSnackbar(`Saved: ${data.name}`);
        setActiveWorkflow(data);
        closeDialog('workflowDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };
  const handleSetShowCompleted = (checked: boolean) => {
    setShowCompleted(checked);
    if (checked) {
      setOrderBy('completedAt');
      setIsDescending(true);
    } else {
      setOrderBy('priorityValue');
      setIsDescending(true);
    }
  };

  const handleSetShowDeleted = (checked: boolean) => {
    setShowDeleted(checked);
    if (checked) {
      setOrderBy('deletedAt');
      setIsDescending(true);
    } else {
      setOrderBy('priorityValue');
      setIsDescending(true);
    }
  };

  const handleSetShowSnoozed = (checked: boolean) => {
    setShowSnoozed(checked);
    if (checked) {
      setOrderBy('snoozeUntil');
      setIsDescending(false);
    } else {
      setOrderBy('priorityValue');
      setIsDescending(true);
    }
  };

  const handleSelectTag = (tag: TypeTag) => {
    console.log('selected tag', tag, selectedTags);
    if (selectedTags.includes(tag)) {
      setSelectedTags(without(selectedTags, tag));
    } else {
      setSelectedTags(uniq(selectedTags.concat([tag])));
    }
  };

  return (
    <PlannerContext.Provider
      value={{
        // global view
        selectedTab,
        setSelectedTab,
        showFilters,
        setShowFilters,
        showChips,
        setShowChips,
        selectedTags,
        handleSelectTag,
        searchValue,
        setSearchValue,
        searchValueRef,
        // task view
        activeTask,
        tasks,
        setTasks,
        handleSetEditTask,
        handleSaveTask,
        orderBy,
        setOrderBy,
        isDescending,
        setIsDescending,
        showCompleted,
        setShowCompleted: handleSetShowCompleted,
        showDeleted,
        setShowDeleted: handleSetShowDeleted,
        showSnoozed,
        setShowSnoozed: handleSetShowSnoozed,
        // workflow view
        activeWorkflow,
        workflows,
        setWorkflows,
        handleSetEditWorkflow,
        handleSaveWorkflow,
        // documents
        documents: [],
        // events
        events: [],
        isPlannerReady: !tasksLoading && !workflowsLoading,
      }}
    >
      {children}
    </PlannerContext.Provider>
  );
};
