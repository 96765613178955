import React from 'react';
import { Button } from '@mui/material';
import { TypeTaskFieldCopy } from '../../../types/coach/TaskFieldCopy';
import { TypeEditTaskField } from '../../../types';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const tagHeaderNames: TypeTaskFieldCopy = {
  name: 'Name',
  completedAt: 'Completed At',
  deletedAt: 'Deleted At',
  dueAt: 'Due Date',
  snoozeUntil: 'Snoozed Until',
  durationEstimate: 'Duration',
  priorityValue: 'Priority',
};

export type ChipGroupProps = {
  items: TypeEditTaskField[];
  selectedItem: TypeEditTaskField;
  onSelectItem: (_tag: TypeEditTaskField) => void;
  isDescending: boolean;
  onSetIsDescending: (_isDescending: boolean) => void;
};

export const ChipGroup = ({
  items,
  selectedItem,
  onSelectItem,
  isDescending,
  onSetIsDescending,
}: ChipGroupProps) => {
  const handleSwitchTag = (tag: TypeEditTaskField) => {
    if (selectedItem === tag) {
      onSetIsDescending(!isDescending);
    } else {
      onSelectItem(tag);
      onSetIsDescending(tag === 'priorityValue');
    }
  };

  return (
    <>
      {items.map((tag, index) => (
        <Button
          sx={{ mr: 1, mb: 1, borderRadius: 20, textTransform: 'none' }}
          key={index}
          size={'small'}
          variant={selectedItem === tag ? 'contained' : 'outlined'}
          onClick={() => handleSwitchTag(tag)}
          endIcon={
            selectedItem === tag ? (
              isDescending ? (
                <ArrowDownward />
              ) : (
                <ArrowUpward />
              )
            ) : null
          }
        >
          {tagHeaderNames[tag]}
        </Button>
      ))}
    </>
  );
};
