import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DurationInput, WorkflowDialog } from '../../_common';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import {
  AuthContext,
  PlannerContext,
  ProjectContext,
} from '../../../providers';
import { useGetTagsByType } from '../../../api';
import { TypeTag, TypeWorkflow, TypeWorkflowStep } from '../../../types';
import { ClearIcon } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import { Add, Clear } from '@mui/icons-material';

export const EditWorkflowCard = () => {
  const { performingAction } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { activeWorkflow, handleSaveWorkflow } = useContext(PlannerContext);

  const [editWorkflowName, setEditWorkflowName] = useState(
    activeWorkflow?.name || '',
  );
  const [editWorkflowTags, setEditWorkflowTags] = useState<TypeTag | null>(
    null,
  );
  const [editWorkflowSteps, setEditWorkflowSteps] = useState<
    TypeWorkflowStep[]
  >([]);
  const [editWorkflowAutoRestart, setEditWorkflowAutoRestart] = useState(false);

  const { data: workflowTags } = useGetTagsByType('task');
  const editWorkflowNameRef = useRef<HTMLInputElement | null>(null);

  const handleEdit = (workflow: TypeWorkflow) => {
    setEditWorkflowName(workflow?.name || '');
    setEditWorkflowSteps(workflow?.workflowStepList || []);
    setEditWorkflowTags(workflow?.tags?.length ? workflow.tags[0] : null);
    setEditWorkflowAutoRestart(workflow?.autoRestart || false);
  };

  const ensureTags = (tag: TypeTag | null) => {
    if (!tag) {
      return [];
    }
    return [
      {
        ...tag,
        type: 'workflow',
        position: 1,
        organization: profile?.organizationContext,
      },
    ];
  };

  const onHandleReset = () => {
    if (!activeWorkflow) {
      return;
    }
    handleEdit(activeWorkflow);
    editWorkflowNameRef.current?.focus();
  };

  const onHandleSave = () => {
    handleSaveWorkflow({
      ...activeWorkflow,
      name: editWorkflowName,
      workflowStepList: editWorkflowSteps,
      tags: ensureTags(editWorkflowTags),
      profile: profile ?? undefined,
      organization: profile?.organizationContext,
    });
  };

  useEffect(() => {
    onHandleReset();
  }, [activeWorkflow]);

  const handleGetOptionLabel = (option: string | TypeTag) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name || '';
  };

  const handleTagOptionEqualToValue = (
    option: TypeTag,
    value: TypeTag | string,
  ) => (typeof value === 'string' ? option.name === value : option === value);

  const handleTagsChange = (
    _event: React.SyntheticEvent,
    newValue: TypeTag | string | null,
  ) => {
    if (!newValue) {
      return;
    }
    setEditWorkflowTags(
      typeof newValue === 'string'
        ? { name: newValue, type: 'workflow' }
        : newValue,
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onHandleSave();
    }
  };

  const handleAddStep = () => {
    setEditWorkflowSteps([
      ...editWorkflowSteps,
      { position: editWorkflowSteps.length + 1 },
    ]);
  };

  const handleRemoveStep = (index: number) => {
    setEditWorkflowSteps(editWorkflowSteps.filter((_step, i) => i !== index));
  };

  const handleAddStepTask = (index: number) => {
    const newSteps = editWorkflowSteps.map((step, i) => {
      if (i === index) {
        return {
          ...step,
          workflowTaskList: [...(step.workflowTaskList || []), { name: '' }],
        };
      }
      return step;
    });
    setEditWorkflowSteps(newSteps);
  };

  const handleEditStepTask = (
    stepIndex: number,
    taskIndex: number,
    value: string,
  ) => {
    const newSteps = editWorkflowSteps.map((step, i) => {
      if (stepIndex === i) {
        return {
          ...step,
          workflowTaskList: step.workflowTaskList?.map((task, j) => {
            if (j === taskIndex) {
              return { ...task, name: value };
            }
            return task;
          }),
        };
      }
      return step;
    });
    setEditWorkflowSteps(newSteps);
  };

  const handleEditStepTaskSnoozeDuration = (
    step: TypeWorkflowStep,
    taskIndex: number,
    duration: number | null,
    oldDuration: number | undefined,
  ) => {
    if (duration === oldDuration) {
      return;
    }
    console.log('snoozeDuration', duration, oldDuration);
    const newSteps = editWorkflowSteps.map((s) => {
      if (s === step) {
        return {
          ...s,
          workflowTaskList: s.workflowTaskList?.map((task, i) =>
            i === taskIndex
              ? { ...task, snoozeDuration: duration ?? undefined }
              : task,
          ),
        };
      }
      return s;
    });
    setEditWorkflowSteps(newSteps);
  };

  const handleEditStepTaskDueAfterDuration = (
    step: TypeWorkflowStep,
    taskIndex: number,
    duration: number | null,
    oldDuration: number | undefined,
  ) => {
    if (duration === oldDuration) {
      return;
    }
    console.log('dueAfterDuration', duration, oldDuration);
    const newSteps = editWorkflowSteps.map((s) => {
      if (s === step) {
        return {
          ...s,
          workflowTaskList: s.workflowTaskList?.map((task, i) =>
            i === taskIndex
              ? { ...task, dueAfterDuration: duration ?? undefined }
              : task,
          ),
        };
      }
      return s;
    });
    setEditWorkflowSteps(newSteps);
  };

  const handleRemoveStepTask = (step: TypeWorkflowStep, taskIndex: number) => {
    const newSteps = editWorkflowSteps.map((s) => {
      if (s === step) {
        return {
          ...s,
          workflowTaskList: s.workflowTaskList?.filter(
            (_task, i) => i !== taskIndex,
          ),
        };
      }
      return s;
    });
    setEditWorkflowSteps(newSteps);
  };

  const stepFields = () => (
    <>
      <Grid item xs={12}>
        {editWorkflowSteps.map((workflowStep, stepIndex) => (
          <Box mt={2} key={`edit-task-links-${stepIndex}`}>
            <Card>
              <CardContent>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={6}>
                    <Typography variant={'h6'}>Step {stepIndex + 1}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    justifyContent={'flex-end'}
                    display={'flex'}
                  >
                    <Button
                      onClick={() => handleRemoveStep(stepIndex)}
                      variant={'outlined'}
                      color={'error'}
                      endIcon={<Clear />}
                    >
                      Remove Step
                    </Button>
                  </Grid>
                  {workflowStep.workflowTaskList?.map((task, taskIndex) => (
                    <Grid
                      key={`edit-task-${stepIndex}-${taskIndex}`}
                      container
                      item
                      xs={12}
                      spacing={1}
                      mb={4}
                    >
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={`Step ${stepIndex + 1} - Task ${
                            taskIndex + 1
                          }`}
                          placeholder={`Step ${stepIndex + 1} - Task ${
                            taskIndex + 1
                          }`}
                          value={task.name}
                          onChange={(event) => {
                            console.log('st fire');
                            handleEditStepTask(
                              stepIndex,
                              taskIndex,
                              event.target.value,
                            );
                          }}
                          onKeyDown={handleKeyPress}
                          disabled={performingAction}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={() => {
                                  handleRemoveStepTask(workflowStep, taskIndex);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DurationInput
                          label={'Snooze Duration'}
                          duration={task.snoozeDuration ?? null}
                          onDurationChange={(duration) => {
                            handleEditStepTaskSnoozeDuration(
                              workflowStep,
                              taskIndex,
                              duration,
                              task.snoozeDuration,
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DurationInput
                          label={'Due After Duration'}
                          duration={task.dueAfterDuration ?? null}
                          onDurationChange={(duration) => {
                            handleEditStepTaskDueAfterDuration(
                              workflowStep,
                              taskIndex,
                              duration,
                              task.dueAfterDuration,
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => handleAddStepTask(stepIndex)}
                      startIcon={<Add />}
                    >
                      Add Task
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleAddStep} startIcon={<Add />}>
          Add Step
        </Button>
      </Grid>
    </>
  );

  const mainFields = () => (
    <>
      <Grid item xs={6}>
        <Autocomplete
          freeSolo
          clearOnBlur
          value={editWorkflowTags}
          options={workflowTags || []}
          getOptionLabel={handleGetOptionLabel}
          filterSelectedOptions
          isOptionEqualToValue={handleTagOptionEqualToValue}
          onChange={handleTagsChange}
          renderInput={(params) => (
            <TextField {...params} label="Project" placeholder="Project" />
          )}
          disabled={performingAction}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={editWorkflowAutoRestart}
              onChange={(_event, checked) =>
                setEditWorkflowAutoRestart(checked)
              }
            />
          }
          label={'Auto-Restart'}
        />
      </Grid>
    </>
  );

  return (
    <WorkflowDialog
      title={'Edit Workflow'}
      saveFn={onHandleSave}
      resetFn={onHandleReset}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder={'Workflow Name'}
            value={editWorkflowName}
            onChange={(event) => setEditWorkflowName(event.target.value)}
            onKeyDown={handleKeyPress}
            disabled={performingAction}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setEditWorkflowName('');
                    editWorkflowNameRef.current?.focus();
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        {mainFields()}
        {stepFields()}
      </Grid>
    </WorkflowDialog>
  );
};
