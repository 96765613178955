import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { TypeEditTaskField } from '../../../types';
import { ChipGroup } from './ChipGroup';
import { ToggleChips } from './ToggleChips';
import {
  CheckCircle,
  CheckCircleOutlined,
  DeleteForever,
  DeleteOutline,
  Info,
  InfoOutlined,
  Snooze,
  SnoozeOutlined,
} from '@mui/icons-material';
import { uniq, without } from 'lodash';
import { PlannerContext } from '../../../providers';

export const TaskFilterAndSort = () => {
  const {
    showCompleted,
    setShowCompleted,
    showDeleted,
    setShowDeleted,
    showSnoozed,
    setShowSnoozed,
    showChips,
    setShowChips,
    isDescending,
    setIsDescending,
    orderBy,
    setOrderBy,
  } = useContext(PlannerContext);

  const [sortFields, setSortFields] = useState<TypeEditTaskField[]>([
    'name',
    'dueAt',
    'durationEstimate',
    'priorityValue',
  ]);

  useEffect(() => {
    if (showCompleted) {
      setSortFields([...sortFields, 'completedAt']);
    } else {
      setSortFields(sortFields.filter((field) => field !== 'completedAt'));
    }
  }, [showCompleted]);

  useEffect(() => {
    if (showCompleted) {
      setSortFields(uniq(sortFields.concat(['completedAt'])));
    } else {
      setSortFields(without(sortFields, 'completedAt'));
    }
  }, [showCompleted]);

  useEffect(() => {
    if (showDeleted) {
      setSortFields(uniq(sortFields.concat(['deletedAt'])));
    } else {
      setSortFields(without(sortFields, 'deletedAt'));
    }
  }, [showDeleted]);

  useEffect(() => {
    if (showSnoozed) {
      setSortFields(uniq(sortFields.concat(['snoozeUntil'])));
    } else {
      setSortFields(without(sortFields, 'snoozeUntil'));
    }
  }, [showSnoozed]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ChipGroup
            items={sortFields}
            selectedItem={orderBy}
            onSelectItem={setOrderBy}
            isDescending={isDescending}
            onSetIsDescending={setIsDescending}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToggleChips
            isActive={showCompleted}
            onToggle={setShowCompleted}
            label={'Show Completed'}
            activeIcon={<CheckCircle />}
            inactiveIcon={<CheckCircleOutlined />}
          />
          <ToggleChips
            isActive={showDeleted}
            onToggle={setShowDeleted}
            label={'Show Deleted'}
            activeIcon={<DeleteForever />}
            inactiveIcon={<DeleteOutline />}
          />
          <ToggleChips
            isActive={showSnoozed}
            onToggle={setShowSnoozed}
            label={'Show Snoozed'}
            activeIcon={<Snooze />}
            inactiveIcon={<SnoozeOutlined />}
          />
          <ToggleChips
            isActive={showChips}
            onToggle={setShowChips}
            label={'Show Chips'}
            activeIcon={<Info />}
            inactiveIcon={<InfoOutlined />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
