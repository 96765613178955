import React, { useContext } from 'react';
import {
  EditTaskCard,
  EventList,
  TabView,
  TaskList,
  WorkflowList,
} from '../../index';
import { PlannerContext, PlannerProvider } from '../../../providers';
import { Container } from '@mui/material';
import { TagsAndFilters } from './TagsAndFilters';
import { PlannerSearch } from './PlannerSearch';
import { Checklist, Event, FormatListNumbered } from '@mui/icons-material';
import { EditWorkflowCard } from '../Workflow';

export const PlannerWrapper = () => {
  const { setSelectedTab } = useContext(PlannerContext);

  const tabs = [
    { name: 'Tasks', icon: <Checklist />, component: TaskList },
    {
      name: 'Workflows',
      icon: <FormatListNumbered />,
      component: WorkflowList,
    },
    {
      name: 'Events',
      icon: <Event />,
      component: EventList,
    },
  ];

  return (
    <Container>
      <TagsAndFilters />
      <PlannerSearch />
      <TabView tabs={tabs} onSelectTab={setSelectedTab} />
      <EditTaskCard />
      <EditWorkflowCard />
    </Container>
  );
};

export const Planner = () => {
  return (
    <PlannerProvider>
      <PlannerWrapper />
    </PlannerProvider>
  );
};
